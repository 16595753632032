import React from 'react';
import styled from 'styled-components';

const StyledDualColumn = styled.div`
	padding: 4rem ${(p) => p.theme.gutter} 20rem;
	&.home {
		padding: 4rem ${(p) => p.theme.gutter} 0.5rem;
	}
	background-image: url(${(p) => p.img});
	background-position: center bottom;
	background-size: 100% auto;
	background-repeat: no-repeat;
	.quick-fact-title {
		font-family: ${(p) => p.theme.fonts.title};
		color: ${(p) => p.theme.color.dark};
		font-weight: 700;
		width: 75%;
		margin: 0 auto 2rem;
		font-size: 1.7rem;
		line-height: 2rem;
	}
	.columns {
		margin-bottom: 2rem;
	}
	.columns-wrapper {
		font-family: ${(p) => p.theme.fonts.body};
		.column {
			margin: 0 auto;
			width: 70%;
			flex: none;
		}
	}

	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide} 20rem;
		&.home {
			padding: 4rem ${(p) => p.theme.gutterWide} 0.5rem;
		}
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide} 28rem;
		.quick-fact-title {
			font-size: 2.1rem;
		}
		.columns-wrapper .column {
			width: 100%;
		}
		&.home {
			padding: 6rem ${(p) => p.theme.gutterXWide} 0.5rem;
		}
	}
	@media all and (max-width: 900px) {
		padding: 4rem ${(p) => p.theme.gutter} 16rem;
	}
	@media all and (max-width: 768px) {
		padding: 3rem ${(p) => p.theme.gutter} 14rem;
		.columns-wrapper .column {
			width: 100%;
		}
		&.home {
			padding: 3rem ${(p) => p.theme.gutter} 0.5rem;
		}
	}
	@media all and (max-width: 668px) {
		padding: 3rem ${(p) => p.theme.gutter} 17rem;
		background-size: 130% auto;
		background-position: right bottom;
	}
	@media all and (max-width: 550px) {
		.quick-fact-title {
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 3rem ${(p) => p.theme.gutterMobile} 13rem;
		&.home {
			padding: 3rem ${(p) => p.theme.gutterMobile};
		}
	}
	@media all and (max-width: 400px) {
		padding: 3rem ${(p) => p.theme.gutterMobile} 11rem;
	}
`;

export default ({ title, image, content, page, id }) => {
	return (
		<StyledDualColumn img={image} className={page}>
			<div className="quick-fact is-small">
				<div className="container">
					{title && (
						<h1 className="has-text-centered quick-fact-title">{title}</h1>
					)}
					<div className="columns-wrapper">
						<div className="columns">
							<div className="column has-text-centered">
								<p>{content}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledDualColumn>
	);
};
