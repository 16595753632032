import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledTiles = styled.div`
	margin: -5rem 0 0;
	padding: 0 0 6rem;
	background-image: url(${(p) => p.bimg});
	background-position: center bottom;
	background-size: 100% auto;
	background-repeat: no-repeat;
	&.home {
		margin: -5rem 0 -5rem;
		padding: 0;
	}
	&.commercial {
		margin: 0;
		padding: 3rem 0 6rem;
	}
	.column {
		background-color: ${(p) => p.theme.color.dark};
		&:nth-child(4n-2),
		&:nth-child(4n-1) {
			background-color: #6b6d71;
		}
	}
	.tile-holder {
		padding: 3rem ${(p) => p.theme.gutter} 4rem;
		color: #fff;

		h2 {
			text-align: center;
			font-family: ${(p) => p.theme.fonts.title};
			font-weight: 700;
			margin-bottom: 1.5rem;
		}

		p {
			font-family: ${(p) => p.theme.fonts.body}, sans-serif;
			text-align: center;
		}

		.box-button {
			margin-top: 2rem;
		}
	}
	.columns {
		display: flex;
	}

	@media all and (min-width: 1200px) {
		.tile-holder {
			padding: 3rem 5rem;
		}
	}
	@media all and (min-width: 1600px) {
		.tile-holder {
			padding: 5rem 4rem;
		}
	}
	@media all and (max-width: 1023px) {
		margin: 0;
		padding: 0;
		&.commercial {
			padding: 0 0 20rem;
		}
	}
	@media all and (max-width: 950px) {
		.column {
			&:nth-child(4n-1) {
				background-color: ${(p) => p.theme.color.dark};
			}
			&:nth-child(4n) {
				background-color: #6b6d71;
			}
		}
	}
	@media all and (max-width: 850px) {
		margin: -0.5rem 0 0;
		&.home {
			margin: -5rem 0 0;
		}
		.tile-holder {
			padding: 2rem 4rem;
		}
		.column {
			&:nth-child(4n-1) {
				background-color: ${(p) => p.theme.color.dark};
			}
			&:nth-child(4n) {
				background-color: #6b6d71;
			}
		}
	}
	@media all and (max-width: 768px) {
		&.home {
			margin: 0;
		}
	}
	@media all and (max-width: 500px) {
		.tile-holder {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
		&.commercial {
			padding: 0 0 15rem;
		}
	}
`;

export default ({ tiles, page, bimg }) => (
	<StyledTiles className={page} bimg={bimg}>
		<div className="container">
			<div className="columns is-multiline is-gapless">
				{tiles.map((item, index) => (
					<div
						className="column is-half-tablet is-full-mobile dark-orange-tile"
						key={index}
					>
						<div className="tile-holder">
							<h2>{item.title}</h2>
							<p>{item.content}</p>
							<div className="box-button has-text-centered">
								<Link to={item.goto} className="ec-button">
									{item.gotoText ? item.gotoText : 'Learn More'}
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	</StyledTiles>
);
