import React from 'react';

import Masthead from '../components/masthead';
import SingleCol from '../components/single-col';
import Tiles from '../components/tiles';
import SectionTestimony from '../components/testimonial';

export default ({ data }) => {
	const {
		mast,
		single,
		tiles,
		testimony,
	} = data.allMarkdownRemark.edges[0].node.frontmatter;
	return (
		<>
			<Masthead
				image={mast.image}
				title={mast.title}
				description={mast.content}
			/>
			<SingleCol image={single.image} content={single.content} id="programs" />
			<Tiles tiles={tiles} />
			<SectionTestimony data={testimony} />
		</>
	);
};
