import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PersonalTemplate from '../templates/personal-template';
import SEO from '../components/seo';

const Personal = ({ data }) => {
	return (
		<Layout page="Personal">
			<SEO
				title="Personal"
				description={
					data.allMarkdownRemark.edges[0].node.frontmatter.description
				}
			/>
			<PersonalTemplate data={data} />
		</Layout>
	);
};

export default Personal;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "personal" } } }) {
			edges {
				node {
					frontmatter {
						description
						mast {
							title
							text
							image
						}
						single {
							image
							content
						}
						tiles {
							title
							goto
							content
						}
						testimony {
							author
							location
							content
						}
					}
				}
			}
		}
	}
`;
